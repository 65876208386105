"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    ADD_POST_SUCCESS: 'retrospected/posts/add/success',
    RECEIVE_POST: 'retrospected/posts/receive/add',
    RECEIVE_BOARD: 'retrospected/posts/receive-all',
    RECEIVE_DELETE_POST: 'retrospected/posts/receive/delete',
    DELETE_POST: 'retrospected/posts/delete',
    LIKE_SUCCESS: 'retrospected/posts/like/success',
    RECEIVE_LIKE: 'retrospected/posts/receive/like',
    EDIT_POST: 'retrospected/posts/edit',
    RECEIVE_EDIT_POST: 'retrospected/posts/receive/edit',
    RENAME_SESSION: 'retrospected/session/rename',
    RECEIVE_SESSION_NAME: 'retrospected/session/receive/rename',
    JOIN_SESSION: 'retrospected/session/join',
    LEAVE_SESSION: 'retrospected/session/leave',
    RECEIVE_CLIENT_LIST: 'retrospected/session/receive/client-list',
    LOGIN_SUCCESS: 'retrospected/user/login/success',
};
