"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultOptions = {
    allowActions: true,
    allowMultipleVotes: false,
    allowSelfVoting: false,
    allowAuthorVisible: false,
    maxDownVotes: null,
    maxUpVotes: null,
};
exports.defaultSession = __assign(__assign({}, exports.defaultOptions), { id: '', columns: [
        { id: '', index: 0, label: '', type: 'well', color: '', icon: null },
        { id: '', index: 1, label: '', type: 'notWell', color: '', icon: null },
        { id: '', index: 2, label: '', type: 'ideas', color: '', icon: null },
    ], name: null, posts: [] });
