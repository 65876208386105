import { Translation } from './types';
export default {
  Header: {
    subtitle: 'A good way of ranting in an Agile way',
    logout: 'ログアウト',
    leave: '退室',
    summaryMode: '要約モード',
  },
  LanguagePicker: {
    header: '言語を選択',
  },
  Main: {
    hint: 'URLを共有すれば新たな参加者を本セッションに招待できます',
  },
  Post: {
    vote: '投票',
    votes: '表決',
    deleteButton: '削除',
    noContent: '(項目が空です)',
    by: undefined,
    upVote: undefined,
    downVote: undefined,
    voteRemainingMultiple: undefined,
    voteRemainingOne: undefined,
    voteRemainingNone: undefined,
  },
  Customize: {
    title: undefined,
    votingCategory: undefined,
    votingCategorySub: undefined,
    postCategory: undefined,
    postCategorySub: undefined,
    customTemplateCategory: undefined,
    customTemplateCategorySub: undefined,
    startButton: undefined,
    maxUpVotes: undefined,
    maxUpVotesHelp: undefined,
    maxDownVotes: undefined,
    maxDownVotesHelp: undefined,
    allowSelfVoting: undefined,
    allowSelfVotingHelp: undefined,
    allowMultipleVotes: undefined,
    allowMultipleVotesHelp: undefined,
    allowActions: undefined,
    allowActionsHelp: undefined,
    allowAuthorVisible: undefined,
    allowAuthorVisibleHelp: undefined,
    template: undefined,
    templateHelp: undefined,
    numberOfColumns: undefined,
    numberOfColumnsHelp: undefined,
  },
  PostBoard: {
    customQuestion: undefined,
    notWellQuestion: '改善できること',
    wellQuestion: '良かったこと',
    ideasQuestion: '共有したいアイディア',
    startQuestion: undefined,
    stopQuestion: undefined,
    continueQuestion: undefined,
    likedQuestion: undefined,
    lackedQuestion: undefined,
    learnedQuestion: undefined,
    longedForQuestion: undefined,
    anchorQuestion: undefined,
    boatQuestion: undefined,
    islandQuestion: undefined,
    windQuestion: undefined,
    rockQuestion: undefined,
    disconnected: undefined,
    reconnect: undefined,
  },
  GameMenu: {
    board: undefined,
    summary: undefined,
  },
  Template: {
    default: undefined,
    wellNotWell: undefined,
    startStopContinue: undefined,
    fourLs: undefined,
    sailboat: undefined,
  },
  Clients: {
    header: '現在の参加者',
  },
  Join: {
    welcome: 'Retrospectedへようこそ',
    standardTab: {
      header: '作成',
      text: 'セッションを作成してレトロスペクティブをはじめる',
      button: '新しいセッションを作成する',
      customizeButton: undefined,
    },
    optionsTab: {
      header: '設定',
      input: '名前',
      button: 'カスタムセッショを作成する',
    },
    previousTab: {
      header: '以前のセッション',
      rejoinButton: '再開',
    },
  },
  Login: {
    namePlaceholder: '名前を入力',
    buttonLabel: 'スタート',
    header: 'Login',
  },
  SummaryBoard: {
    noPosts: '表示する項目がありません',
    copyAsMarkdown: undefined,
    copyAsRichText: undefined,
    copySuccessful: undefined,
  },
  SessionName: {
    defaultSessionName: '新しいレトロスペクティブ',
  },
  Invite: {
    inviteButton: '招待',
    dialog: {
      title: 'レトロスペクティブに招待する',
      text: '下記のURLを共有して本セッションに招待できます',
      copyButton: 'URLをクリップボードにコピー',
    },
  },
  Generic: {
    ok: 'OK',
    cancel: 'キャンセル',
  },
  Actions: {
    tooltip: 'アクションを作成する',
    label: 'アクションパネルを開く',
    summaryTitle: 'あなたの行動',
    title: 'アクション',
  },
} as Translation;
