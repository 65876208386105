import { Translation } from './types';
export default {
  Header: {
    subtitle: 'أسلوب جيّد للتّوبيخ بطريقة مرنة ',
    logout: 'خروج',
    leave: 'غادر',
    summaryMode: 'النّمط الملخّص',
  },
  LanguagePicker: {
    header: 'إختيار اللُّغة',
  },
  Main: {
    hint: 'يمكنُكَ دعوة أشخاص إلى هذه الجلسة عن طريق نسخ عنوان هذه الصفحة',
  },
  Post: {
    vote: 'صوت',
    votes: 'أصوات',
    deleteButton: 'حذف',
    noContent: '(هذا الموضوع فارغ)',
    by: undefined,
    upVote: undefined,
    downVote: undefined,
    voteRemainingMultiple: undefined,
    voteRemainingOne: undefined,
    voteRemainingNone: undefined,
  },
  Customize: {
    title: undefined,
    votingCategory: undefined,
    votingCategorySub: undefined,
    postCategory: undefined,
    postCategorySub: undefined,
    customTemplateCategory: undefined,
    customTemplateCategorySub: undefined,
    startButton: undefined,
    maxUpVotes: undefined,
    maxUpVotesHelp: undefined,
    maxDownVotes: undefined,
    maxDownVotesHelp: undefined,
    allowSelfVoting: undefined,
    allowSelfVotingHelp: undefined,
    allowMultipleVotes: undefined,
    allowMultipleVotesHelp: undefined,
    allowActions: undefined,
    allowActionsHelp: undefined,
    allowAuthorVisible: undefined,
    allowAuthorVisibleHelp: undefined,
    template: undefined,
    templateHelp: undefined,
    numberOfColumns: undefined,
    numberOfColumnsHelp: undefined,
  },
  PostBoard: {
    customQuestion: undefined,
    notWellQuestion: 'ما الذي يمكن تحسينه ؟',
    wellQuestion: 'ما الذي فُعِلَ بنجاح؟',
    ideasQuestion: 'هل من أيِّ فكرةٍ ذكيّة ؟',
    startQuestion: undefined,
    stopQuestion: undefined,
    continueQuestion: undefined,
    likedQuestion: undefined,
    lackedQuestion: undefined,
    learnedQuestion: undefined,
    longedForQuestion: undefined,
    anchorQuestion: undefined,
    boatQuestion: undefined,
    islandQuestion: undefined,
    windQuestion: undefined,
    rockQuestion: undefined,
    disconnected: undefined,
    reconnect: undefined,
  },
  GameMenu: {
    board: undefined,
    summary: undefined,
  },
  Template: {
    default: undefined,
    wellNotWell: undefined,
    startStopContinue: undefined,
    fourLs: undefined,
    sailboat: undefined,
  },
  Clients: {
    header: 'الضيوف الحاضرين معنا الآن:',
  },
  Join: {
    welcome: ' ! مرحبا',
    standardTab: {
      header: 'موضوع جديد',
      text: ': انقر أدناه و إبدء إعادة النظر',
      button: 'إنشاء جلسة عمل جديدة',
      customizeButton: undefined,
    },
    optionsTab: {
      header: 'أكثر',
      input: 'عنوان',
      button: 'إنشاء جلسة مخصصة',
    },
    previousTab: {
      header: 'الجلسات السابقة',
      rejoinButton: 'الإنضمام',
    },
  },
  Login: {
    namePlaceholder: 'من أنت بالضبط؟ أدخل اسمك هنا',
    buttonLabel: 'لنبدأ',
    header: 'تسجيل الدخول',
  },
  SummaryBoard: {
    noPosts: 'لا توجد مشاركات للعرض',
    copyAsMarkdown: undefined,
    copyAsRichText: undefined,
    copySuccessful: undefined,
  },
  SessionName: {
    defaultSessionName: 'جلسة جديدة',
  },
  Invite: {
    inviteButton: 'دعوة',
    dialog: {
      title: 'دعوة أشخاص إلى الجلسة',
      text: 'لدعوة أشخاص إلى الجلسة يمكنك إرسال عنوان هذه الصفحة',
      copyButton: 'نسخ العنوان',
    },
  },
  Generic: {
    ok: 'OK',
    cancel: 'Cancel',
  },
  Actions: {
    tooltip: 'إنشاء إجراء على ظهر هذا العنصر',
    label: 'افتح لوحة Action',
    summaryTitle: 'أفعالك',
    title: 'عمل',
  },
} as Translation;
