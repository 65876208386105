import { Translation } from './types';
export default {
  Header: {
    subtitle: '駕馭敏捷方式的好方法',
    logout: '登出',
    leave: '離開',
    summaryMode: '主旨模式',
  },
  LanguagePicker: {
    header: '切換語言',
  },
  Main: {
    hint: '複製並分享網址，即可讓別人加入此議程',
  },
  Post: {
    vote: '投票',
    votes: '表決',
    deleteButton: '刪除',
    noContent: '(目前無任何內容)',
    by: undefined,
    upVote: undefined,
    downVote: undefined,
    voteRemainingMultiple: undefined,
    voteRemainingOne: undefined,
    voteRemainingNone: undefined,
  },
  Customize: {
    title: undefined,
    votingCategory: undefined,
    votingCategorySub: undefined,
    postCategory: undefined,
    postCategorySub: undefined,
    customTemplateCategory: undefined,
    customTemplateCategorySub: undefined,
    startButton: undefined,
    maxUpVotes: undefined,
    maxUpVotesHelp: undefined,
    maxDownVotes: undefined,
    maxDownVotesHelp: undefined,
    allowSelfVoting: undefined,
    allowSelfVotingHelp: undefined,
    allowMultipleVotes: undefined,
    allowMultipleVotesHelp: undefined,
    allowActions: undefined,
    allowActionsHelp: undefined,
    allowAuthorVisible: undefined,
    allowAuthorVisibleHelp: undefined,
    template: undefined,
    templateHelp: undefined,
    numberOfColumns: undefined,
    numberOfColumnsHelp: undefined,
  },
  PostBoard: {
    customQuestion: undefined,
    notWellQuestion: '哪些已經改善?',
    wellQuestion: '哪些做的很好?',
    ideasQuestion: '分享優秀的點子?',
    startQuestion: undefined,
    stopQuestion: undefined,
    continueQuestion: undefined,
    likedQuestion: undefined,
    lackedQuestion: undefined,
    learnedQuestion: undefined,
    longedForQuestion: undefined,
    anchorQuestion: undefined,
    boatQuestion: undefined,
    islandQuestion: undefined,
    windQuestion: undefined,
    rockQuestion: undefined,
    disconnected: undefined,
    reconnect: undefined,
  },
  GameMenu: {
    board: undefined,
    summary: undefined,
  },
  Template: {
    default: undefined,
    wellNotWell: undefined,
    startStopContinue: undefined,
    fourLs: undefined,
    sailboat: undefined,
  },
  Clients: {
    header: '已加入我們的:',
  },
  Join: {
    welcome: '歡迎 Retrospected',
    standardTab: {
      header: '新增',
      text: '點擊以下開始進行回顧:',
      button: '建立新的議程',
      customizeButton: undefined,
    },
    optionsTab: {
      header: '進階設定',
      input: '名稱',
      button: '建立議程',
    },
    previousTab: {
      header: '之前的議程',
      rejoinButton: '再次加入',
    },
  },
  Login: {
    namePlaceholder: '您是誰? 在此輸入您的名稱',
    buttonLabel: '開始!',
    header: 'Login',
  },
  SummaryBoard: {
    noPosts: '目前無任何內容',
    copyAsMarkdown: undefined,
    copyAsRichText: undefined,
    copySuccessful: undefined,
  },
  SessionName: {
    defaultSessionName: '我的回顧',
  },
  Invite: {
    inviteButton: '邀請',
    dialog: {
      title: '邀請別人來到您的議程',
      text: '邀請別人來到您的議程，只要分享以下的網址給他們',
      copyButton: '複製網址到剪貼簿',
    },
  },
  Generic: {
    ok: 'OK',
    cancel: '取消',
  },
  Actions: {
    tooltip: '在此項目的背面創建一個操作',
    label: '打開“操作”面板',
    summaryTitle: '你的行動',
    title: '行動',
  },
} as Translation;
